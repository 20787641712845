.hero-section-bg-pic-mobile{
    display: none;
}

.hero-section {
    height: 100vh;
}

.hero-section-bg-pic {
    z-index: -10;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
}

.fastest-growing-seaconvoy {
    position: absolute;
    width: 600px;
    margin: 60px 0 0 100px;
}

@media only screen and (max-width: 767px) {
    .hero-section-bg-pic {
        display: none;
    }
    .hero-section {
        height: 60vh;
    }
    .fastest-growing-seaconvoy {
        top: 0;
        bottom: 0;
        margin: 38% 20px;
        width: 75%;
    }

    .hero-section-bg-pic-mobile {
        display: block;
        z-index: -10;
        left: 0;
        width: 100%;
        height: 60vh;
        position: absolute;
    }
}