.meet-the-team {
    margin: 0;
}
.meet-the-team-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.team-members {
    display: grid;

    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(5, 0.5fr);
    gap: 20px 0px;
}

.team-members img {
    /* left: 50%;
    transform: translate(37.5%, 0); */
    margin: 30px auto;
    width: 90%;
}

@media only screen and (max-width: 767px) {
    .meet-the-team-container {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 30px;
    }


    .meet-the-team {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 20px;
    }


    .team-members {
        display: grid;

        grid-template-columns: repeat(2, 1fr);
        gap: 15px 0px;
    }

    .team-members img {
        margin: 0 auto;
        width: 95%;
    }

}