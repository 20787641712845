.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.image-viewer-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80vw;
    /* height: 80vh; */
    overflow-y: auto;
}

.image-viewer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.image-viewer-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    /* height: 80vh; */
}

.full-image {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;
}
