.simulator-container {
  background-image: url(/public/images/tools/hydrofoil-simulator/hydrofoil=simulator-background.jpg);
  /* background-position: -200px -500px; */
  background-size: 100vw 150vh;
  background-repeat: no-repeat;
  height: 150vh;
}

.simulator {
  height: max-content;
  width: 50vw;
  background-color: rgb(255, 255, 255);
  opacity: 0.85;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.simulator-title {
  margin: 10px auto;
  font-size: 48px;
  margin: 0 auto;
  padding: 20px 0;
}

.simulator-form {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0.2rem 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . ."
    "m1 . . time-period"
    "m2 . . time-period"
    "m3 . . time-period"
    "m4 . . time-period"
    "submit-btn submit-btn submit-btn submit-btn";
}

#time-period {
  grid-area: time-period;
}

input[type="number"] {
  width: 60px;
  margin: 0 auto;
  align-self: center;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 500;
}

.hydrolic-submit-btn {
  background-color: #00326c;
  grid-area: submit-btn;
  font-size: 22px;
}

.video-modal {
  z-index: 100;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-content {
  position: relative;
}

.video-content video {
  /* max-width: 90%;
  max-height: 90%; */
}

.close-btn {
  position: absolute;
  right: 0px;
  background-color: #00326c;
  padding: 10px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.motor-conatiner {
  /* display: inline;
  grid-area: m1; */
  display: flex;
}
.motor2 {
  grid-area: m2;
}
.motor3 {
  grid-area: m3;
}
.motor4 {
  grid-area: m4;
}
