.tool-card-container {
  height: 350px;
  width: 350px;
  box-shadow: 4px 6px 13px #005669;
  margin: 5%;
}

.tool-index {
  z-index: -1;
  opacity: 0.2;
  position: absolute;
  color: #005669;
  font-size: 400px;
  font-weight: 800;
  margin: -70px 20px;
}

.tool-card-content {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.tool-card-title {
  font-size: 45px;
  margin-bottom: 50px;
  font-weight: 500;
  color: #005669;
}

.tool-card-btn {
  position: absolute;
  border: none;
  width: max-content;
  padding: 10px;
  background-color: #005669;
  color: white;
  margin: 280px 210px;
}
.tool-card-btn a {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

/* Responsive */
@media (max-width: 768px) {
  .tool-card-container {
    height: 250px;
    width: 85%;
    box-shadow: 4px 6px 13px #005669;
    margin: 10% auto;
  }

  .tool-index {
    font-size: 275px;
    margin: -40px 20px;
  }

  .tool-card-title {
    font-size: 30px;
  }

  .tool-card-btn {
    position: absolute;
    border: none;
    width: max-content;
    padding: 10px;
    background-color: #005669;
    color: white;
    margin: 190px 55%;
  }
  .tool-card-btn a {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}
