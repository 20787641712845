.tool-list-container {
    display: flex;
    flex-direction: row;
}

/* Responsive */
@media (max-width: 768px) {
    .tool-list-container {
        display: flex;
        flex-direction: column;
    }
    
}