.mgallery-section {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.mgallery-grid {
  margin-top: 100px;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  gap: 20px;
}

.mgallery-item img {
  width: 600px;
  height: 400px;
  object-fit: cover;
}

.sized-box-gallery-page {
  /* height: 200px; */
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .mgallery-section {
    width: 90%;
    margin: 0 auto;
  }
  .mgallery-grid {
    margin: 30px 0;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-around;
    gap: 20px;
  }

  .mgallery-item img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  .sized-box-gallery-page {
    display: none;
  }
}

