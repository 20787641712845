.login-container {
  max-width: 400px;
  margin: 25vh auto;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
}

.login-heading {
  font-size: 40px;
  font-weight: 600;
  margin: 30px 0;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  padding: 10px;
  background-color: #00326c;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 0;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.additional-options {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.additional-options a {
  text-decoration: none;
  color: #007bff;
}

.additional-options a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .login-container {
  padding: 0 20px;

    max-width: 100%;
  }
}
