.card {
  width: 728px; /* Maximum width to maintain layout */
  border: 0;
  display: flex; /* Use Flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  overflow: hidden; /* Prevent overflow */
  max-height: 485px;
  margin-bottom: 0;
}

.card img {
  width: 100%; /* Responsive image */
   /* Maximum height, consider making this responsive too */
  object-fit: cover; /* Adjust how the image fits */
}

.card-desc {
  padding: 0px; /* Add some padding */
  overflow: auto; /* Allow scrolling for overflow content */
  margin: 0px;
}

.card-heading, .card-desc p, .card-desc a {
  margin: 0px;
}

.card-desc a {
  display: inline-block; /* Improve link styling */
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding */
}

.card-desc p {
  /* Adjust paragraph styling as needed */
}
