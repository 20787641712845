.gallery-section-mobile {
  display: none;
}

.gallery-section {
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}

.gallery-grid {
  margin-top: 50px;
  display: grid;
  /* width: 1272px; */
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  gap: 20px;
}

.gallery-item img {
  width: 425px;
  height: 270px;
  object-fit: cover;
}

.sized-box {
  height: 30px;
  width: 100%;

}

.more-images-btn {
  background: #005669;
  color: white;
  margin: 30px;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .gallery-section {
    display: none;
  }

  .gallery-section-mobile {
    display: block;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .gallery-heading {
    width: 95%;
  }

  .gallery-grid {
    margin-top: 20px;
    display: grid;
    /* width: 1272px; */
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-around;
    gap: 10px;
  }

  .gallery-item img {
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .sized-box {
    height: 20px;
    width: 100%;
  }
}