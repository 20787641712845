.image-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-popup-container {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.image-popup-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.popup-image {
    max-width: 600px; /* Adjust as needed */
    cursor: pointer;
}



















/* .image-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.image-popup-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.image-popup-item {
    cursor: pointer;
}

.popup-thumbnail {
    width: 100%;
    height: 200px; 
    object-fit: cover;
} */
