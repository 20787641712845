@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500&family=Poppins:wght@200;400&display=swap');

* {
    font-family: 'Montserrat', sans-serif;

    text-decoration: none;
    list-style: none;

    --bg-color: white;
    --primary-color: #005669; 

}

.App {
  margin: 0 auto;
  max-width: 100vw;
  text-align: center;
}

.App-logo {
  height: 40vmin; 
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
} */
